import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { UploadedImage } from '@ct/core';
import { ImageUploadApiService } from '@ct/shared/services/image-upload-api.service';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

export interface CardWrapperItem {
  title?: string;
  photoIds: string[];
  entries?: CardWrapperItemEntry[];
  startDate?: Date;
  endDate?: Date;
  createdOn?: string | Date;
  publishedOn?: string | Date;
}

export interface CardWrapperItemEntry {
  photoIds?: string[];
}

@Component({
  selector: 'ct-card-wrapper',
  templateUrl: './card-wrapper.component.html',
  styleUrls: ['./card-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardWrapperComponent implements OnChanges, OnDestroy {
  @Input() readonly item: CardWrapperItem | undefined;
  @Input() readonly link: string[] | string;
  @Input() readonly hasDateRange: boolean;
  @Input() readonly hasExactDate: boolean;
  @Input() readonly isHeading: boolean;
  @Input() noImagePlaceholderPath: string;
  @Input() readonly imageUrl: string | undefined;
  @Input() readonly imageThumbnail: string | undefined;

  image: UploadedImage;

  get photoId() {
    if (this.item) {
      return this.item.photoIds?.[0] || this.item.entries?.[0]?.photoIds?.[0];
    }
  }

  private destroyed$ = new Subject<void>();

  constructor(private imageUploadApiService: ImageUploadApiService, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && this.item) {
      this.loadImage();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadImage() {
    if (this.photoId) {
      return this.imageUploadApiService
        .getById(this.photoId)
        .pipe(takeUntil(this.destroyed$), catchError(() => of(null)))
        .subscribe((image) => {
          this.image = image ?? ({} as UploadedImage);
          this.cdr.detectChanges();
        });
    }

    this.image = {} as UploadedImage;
    this.cdr.detectChanges();
  }
}
