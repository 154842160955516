import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  enableServiceWorker: true,
  apiBaseUrl: 'https://api-dev.camping.tools/api',
  identityApiBaseUrl: 'https://api-dev.camping.tools/api/user/identity',
  userRelationsApiBaseUrl: 'https://api-dev.camping.tools/api/user-relations',
  userProfileApiBaseUrl: 'https://api-dev.camping.tools/api/user',
  merchantProfileApiBaseUrl: 'https://api-dev.camping.tools/api/merchant',
  marketplaceApiBaseUrl: 'https://api-dev.camping.tools/api/marketplace',
  shareApiBaseUrl: 'https://api-dev.camping.tools/api/share',
  reportsApiBaseUrl: 'https://api-dev.camping.tools/api/reports',
  emailApiBaseUrl: 'https://api-dev.camping.tools/api/email-api',
  fileUploadApiBaseUrl: 'https://api-dev.camping.tools/api/file-upload',
  blogApiBaseUrl: 'https://api-dev.camping.tools/api/blog',
  seriesApiBaseUrl: 'https://api-dev.camping.tools/api/series',
  threadApiBaseUrl: 'https://api-dev.camping.tools/api/threads',
  listsApiBaseUrl: 'https://api-dev.camping.tools/api/lists',
  listItemsApiBaseUrl: 'https://api-dev.camping.tools/api/list-items',
  groupApiBaseUrl: 'https://api-dev.camping.tools/api/groups',
  tripApiBaseUrl: 'https://api-dev.camping.tools/api/trips',
  exploreApiBaseUrl: 'https://api-dev.camping.tools/api/explore',
  itineraryApiBaseUrl: 'https://api-dev.camping.tools/api/itinerary',
  likeApiBaseUrl: 'https://api-dev.camping.tools/api/likes',
  notificationsApiBaseUrl: 'https://api-dev.camping.tools/api/notifications',
  journalEntryApiBaseUrl: 'https://api-dev.camping.tools/api/journal-entries',
  searchApiBaseUrl: 'https://api-dev.camping.tools/api/search',
  tagApiBaseUrl: 'https://api-dev.camping.tools/api/tags',
  videoApiBaseUrl: 'https://api-dev.camping.tools/api/video',
  channelApiBaseUrl: 'https://api-dev.camping.tools/api/channel',
  adminUserProfileApiBaseUrl: 'https://admin-backend-dev-dot-camping-tools-dev-2.ue.r.appspot.com/api/user',
  adminBlogApiBaseUrl: 'https://admin-backend-dev-dot-camping-tools-dev-2.ue.r.appspot.com/api/blog',
  adminEmailApiBaseUrl: 'https://admin-backend-dev-dot-camping-tools-dev-2.ue.r.appspot.com/api/email-api',
  adminRolesApiBaseUrl: 'https://admin-backend-dev-dot-camping-tools-dev-2.ue.r.appspot.com/api/roles',
  adminGroupsApiBaseUrl: 'https://admin-backend-dev-dot-camping-tools-dev-2.ue.r.appspot.com/api/groups',
  transcodedVideosBucket: '',
  reCaptchaSiteKey: '6Lf4stEaAAAAADjeNInBuKhjPMRmcIw8o1LAWvun',
  googleMapsApiKey: 'AIzaSyCFn1jhAF218k6IIc6v9TLTFTgslKTOdHM',
  gaTrackingId: '',
  gtmId: '',
  activeCampaignId: '',
  fbPixelId: '',
  webUrl: 'https://dev.camping.tools',
  staticUrl: 'https://static-dev.camping.tools',
  sentry: {
    dsn: 'https://13210badf472430db45d8eac6f077b7d@o1132589.ingest.sentry.io/6194938',
    sampleRate: 1,
    tracesSampleRate: 0,
    tracingOrigins: ['dev.camping.tools', 'api-dev.camping.tools'],
    environment: 'development',
    enabled: true
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBALIIZYOVPSut_Y48H3vCGCceEX3cX4KI',
    authDomain: 'camping-tools-dev-2.firebaseapp.com',
    projectId: 'camping-tools-dev-2',
    storageBucket: 'camping-tools-dev-2.appspot.com',
    messagingSenderId: '263035246449',
    appId: '1:263035246449:web:f71f7a93eac858ff9848d0',
    measurementId: 'G-466PFNFW32',
    vapidKey: 'BAKzTdagSdBdxeBJ7Qlsk4W-N_PkT8MY5y7OCyJraMaRQMD0tDlhO6H4GoClAhiV_WBjS8t5mM0_R7NGIcjc6R8'
  },
  opentelemetry: {
    application: {
      name: 'dev.app.retailer',
      version: 'beta'
    },
    tracing: {
      enabled: false,
      propagate: ['/api', 'https://api-dev.camping.tools'],
      whitelist: ['/api', 'https://api-dev.camping.tools'],
      url: 'https://otel-node-prod-dot-prod-camping-tools.uc.r.appspot.com/v1/traces'
    },
    metrics: {
      enabled: false,
      url: 'https://otel-node-prod-dot-prod-camping-tools.uc.r.appspot.com/v1/metrics'
    }
  }
};
